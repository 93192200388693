<template>
  <div ref="mymodal" class="modal fade createNewModal" id="CreateNewAnswerModal" tabindex="-1" aria-hidden="true"
    @click.self="handleBackdropClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Respuesta</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.name && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Nombre
                  </label>
                  <input v-model="item.name" type="text" class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Johny" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.question && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Pregunta
                  </label>
                  <select v-model="item.question" class="form-control shadow-none rounded-0 text-black">
                    <option v-for="(questionOption, index) in questions" :value="questionOption.id" :key="index">
                      {{ questionOption.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25',
                  {
                    'has-error':
                      item.correct !== true &&
                      item.correct !== false &&
                      showError
                  }]">
                  <label class="text-black fw-semibold mb-10">
                    Seleccione si es Correcta o No
                  </label>
                  <select v-model="item.correct" class="form-control shadow-none rounded-0 text-black"
                    :required.attr="true">
                    <option :value="true">Correcta</option>
                    <option :value="false">Incorrecta</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.value && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Respuesta
                  </label>
                  <input v-model="item.value" type="text" class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Johny" />
                </div>
              </div>

            </div>
            <div v-if="error !== '' || showError">
              <div class="row" v-if="error != null || showError">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div v-if="success">
              <div class="row" v-if="success">
                <span class="success-message ms-2 mb-6 me-6">{{ message }}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between;">
              <button
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="button" data-bs-dismiss="modal" aria-label="Close">
                Cancelar
              </button>
              <button
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15"
                type="submit" :disabled="showError" @click.prevent="submitForm">
                {{ title }} Respuesta
              </button>
            </div>
          </form>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";

export default {
  name: "CreateNewAnswerModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    },
    id: {
      type: Number,
      required: false,
    },
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    question: Object,
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: '',
      success: false,
      message: '',
      showError: false,
      maxLen: 10,
      minLen: 8,
      item: {
        name: '',
        value: null,
        question: null,
        correct: null,
      },
      questions: []
    };
  },
  watch: {
    async id(newVal) {
      // console.log('newVal: ', newVal)
      if (newVal > 0) {
        await StateStore.actions.actionsGetAnswers({ 'axios': this.axios, 'id': newVal });
        this.item = await StateStore.getters.getAnswerRetrieve();
        // console.log("itemRetrieve: ", this.item);
      } else if (newVal === undefined) {
        this.item = [];
      }
    }
  },
  mounted() {
    this.$refs.mymodal.addEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  methods: {
    validatePassword() {
      if (this.password !== this.password2) {
        this.showError = true;
        this.error = 'Las contraseñas no coinciden';
        this.alert = true;
        // console.log("password don't match");
      } else {
        this.error = '';
        this.alert = false;
        this.showError = false;
        // console.log("password match");
      }
    },
    checkMinLength(field, minLen) {
      if (field === 'phone' && this.item[field] && (this.item[field].toString())?.length < minLen) {
        this.error = `El campo debe tener más de ${minLen ? minLen : this.minLen} caracteres`;
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item[field] && this.item[field]?.length < (minLen ? minLen : this.minLen)) {
        this.error = `El campo debe tener más de ${minLen ? minLen : this.minLen} caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (field === 'phone') {
        if (isNaN(this.item[field])) {
          this.showError = true;
          this.error = `Número de Teléfono invalido, rectifiquelo`;
        }
        if (this.item && this.item[field] && (this.item[field].toString())?.length > maxLen) {
          this.error = `El campo no puede tener más de ${maxLen ? maxLen : this.maxLen} caracteres`;
          this.showError = true;
        }
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item && this.item[field] && this.item[field]?.length >= (maxLen ? maxLen : this.maxLen)) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${maxLen ? maxLen : this.maxLen} caracteres`;
        // console.log("error: ", field);
        // console.log("error: ", this.error);
        // console.log("error: ", this.showError);
      } else {
        this.showError = false;
        this.error = '';
      }
    },
    handleModalHidden() {
      this.$emit('modal-closed');
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    async getAllQuestions() {
      await StateStore.actions.actionsGetAllQuestionsList({ 'axios': this.axios });
      this.questions = StateStore.getters.getQuestionsList();
    },
    async submitForm() {
      // console.log("this.item.correct: ", this.item.correct)
      this.showError = true;
      if (!this.item.name || !this.item.value || !this.item.question || this.item.correct === null || this.item.correct === undefined) {
        this.error = 'Todos los campos son necesarios';
      } else {
        this.error = '';
        this.showError = false;
        this.success = true;
        if (this.item.id > 0) {
          this.message = 'Respuesta Modificada con éxito...';
        } else {
          this.message = 'Respuesta Creada con éxito...';
        }
        try {
          if (this.item.id === undefined) {
            // console.log('this.id: ', this.id);
            await StateStore.actions.actionsCreateAnswers({
              'axios': this.$axios,
              'name': this.item.name,
              'value': this.item.value,
              'question': this.item.question,
              'correct': this.item.correct,
            })
          } else {
            await StateStore.actions.actionsEditAnswers({
              'axios': this.$axios,
              'id': this.item.id,
              'name': this.item.name,
              'value': this.item.value,
              'question': this.item.question,
              'correct': this.item.correct,
            })
          }
          location.reload()
        } catch (error) {
          console.error('Error al crear la Respuesta:', error);
        }
        // console.log('Formulario enviado:', this.item);
      }
    },
  },
  created() {
    // console.log("props item: ", this.item);
    this.getAllQuestions();
  },
};
</script>
<style scoped>
.form-group {
  position: relative;
}

.form-control {
  padding-right: 40px;
  /* Espacio para el botón */
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  /* Ajusta esto según sea necesario */
}

.d-block {
  color: #FFFFFF !important;
}

.has-error input,
.has-error select {
  border-color: red;
}

.success-message {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}
</style>