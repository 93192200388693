<template>
  <BreadCrumb PageTitle="Cambiar Contraseña" />  
  <ChangePassword :axios="$axios"/>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ChangePassword from "../../components/Settings/ChangePassword/ChangePassword.vue";

export default defineComponent({
  name: "ChangePasswordPage",
  components: {
    BreadCrumb,
    ChangePassword,
  },
});
</script>