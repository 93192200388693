<template>
  <div
    class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box"
  >
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="profile-info d-sm-flex align-items-center">
        <!-- <img
          src="../../../assets/images/user/user14.jpg"
          class="rounded"
          alt="user"
        /> -->
        <div class="title mt-12 mt-sm-0">
          <h5 class="text-black fw-medium mb-8">
            {{ user.firstName }} {{ user.lastName }}
          </h5>
          <span class="d-block fs-md-15 fs-lg-16 text-dark-emphasis mb-8">
            <template v-if="user?.roles === '1'">
              Super Administrador
            </template>
            <template v-else-if="user?.roles === '2'"> Operador </template>
          </span>
          <!--          <span class="d-block fw-bold text-primary">CESPT IT Solution</span>-->
        </div>
      </div>
      <div
        class="border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30"
      ></div>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="fw-medium text-black-emphasis mb-0">Información Personal</h5>
        <a
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#createNewUserModal"
        >
          Editar
        </a>
      </div>
      <ul class="info mt-25 ps-0 mb-0 list-unstyled">
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center">
            <i class="flaticon-mail-inbox-app"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold"
            >Correo Electrónico</span
          >
          <a
            href="mailto:{{user.email}}"
            class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none"
          >
            {{ user.email }}
          </a>
        </li>
        <!-- <li class="position-relative">
          <div class="icon text-info rounded-circle text-center">
            <i class="flaticon-telephone-call"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Teléfono</span>
          <a
            href="tel:+1-321-456-8756"
            class="d-inline-block fs-md-15 fs-lg-16 text-muted text-decoration-none"
          >
            {{ user.phone }}
          </a>
        </li> -->
        <!--        <li class="position-relative">-->
        <!--          <div class="icon text-primary rounded-circle text-center">-->
        <!--            <i class="flaticon-web"></i>-->
        <!--          </div>-->
        <!--          <span class="d-block text-black mb-5 fw-semibold">Website</span>-->
        <!--          <a-->
        <!--            href="#"-->
        <!--            target="_blank"-->
        <!--            class="d-inline-block fs-md-15 fs-lg-16 text-muted text-decoration-none"-->
        <!--          >-->
        <!--            Victorjames.com-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li class="position-relative">-->
        <!--          <div class="icon text-success rounded-circle text-center">-->
        <!--            <i class="flaticon-money"></i>-->
        <!--          </div>-->
        <!--          <span class="d-block text-black mb-5 fw-semibold">-->
        <!--            Annual Revenue-->
        <!--          </span>-->
        <!--          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">-->
        <!--            $ 24000 USD-->
        <!--          </span>-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
  <CreateNewUserModal
    :axios="axios"
    :id="user?.id"
    :no-close-on-backdrop="true"
    :title="'Editar '"
    ref="createNewUserModal"
  />
</template>

<script>
import * as StateStore from "@/utils/store";
import CreateNewUserModal from "@/components/Users/UsersList/CreateNewUserModal.vue";

export default {
  name: "ProfileContent",
  components: { CreateNewUserModal },
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  data() {
    return {
      idItem: -1,
      error: "",
      showError: false,
      maxLen: 10,
      minLen: 8,
      showPassword: false,
      password: "",
      password2: "",
      user: {
        firstName: "",
        lastName: "",
        roles: "",
        username: "",
        phone: "",
      },
      roles: [],
    };
  },
  watch: {
    async idItem(newVal) {
      // console.log('newVal: ', newVal)
      if (newVal > 0) {
        await StateStore.actions.actionsGetUser({
          axios: this.$axios,
          id: newVal,
        });
        this.user = await StateStore.getters.getUserRetrieve();
        // console.log("itemRetrieve: ", this.user);
      } else if (newVal === undefined) {
        this.user = [];
      }
    },
  },
  methods: {
    openModal() {
      this.$refs.createNewUserModal.open();
    },
  },
  async created() {
    // console.log('router: ', this.$route.params.userId);
    this.idItem =
      this.$route.params.userId !== undefined ? this.$route.params.userId : -1;
    // console.log('userID: ', this.idItem);

    await StateStore.actions.actionsGetUser({
      axios: this.axios,
      id: this.idItem,
    });
    this.user = await StateStore.getters.getUserRetrieve();
  },
};
</script>
