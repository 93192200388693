<template>
  <BreadCrumb PageTitle="Versionado de la aplicación" PageParent="Administración" parentRoute="/users-list"/>
  <AppVersionList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AppVersionList from "../../components/AppVersion/AppVersionList.vue";

export default defineComponent({
  name: "AppVersionListPage",
  components: {
    BreadCrumb,
    AppVersionList,
  }
});
</script>