<template>
  <div class="row">
    <div class="col-xxl-8 order-2 order-xl-1">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing settings-card"
      >
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <form @submit.prevent="handleChangePassword">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Contraseña anterior
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                v-model="oldPassword"
                placeholder="**********"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nueva contraseña
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                v-model="newPassword"
                placeholder="**********"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Confirmar Contraseña
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                v-model="confirmPassword"
                placeholder="**********"
                required
              />
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button
                type="button"
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                @click="handleCancel"
              >
                <i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"
                ></i>
                <span class="position-relative">Cancel</span>
              </button>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Cambiar Contraseña
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showError: false,
    };
  },
  methods: {
    async handleChangePassword() {
      if (this.newPassword !== this.confirmPassword) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Las contraseñas no coinciden.",
        });
        return;
      }

      const result = await Swal.fire({
        title: "¿Estás seguro de que quieres cambiar la contraseña?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, cambiar contraseña",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          const response = await StateStore.actions.actionsChangePassword({
            axios: this.axios,
            oldPassword: this.oldPassword,
            newPassword1: this.newPassword,
            newPassword2: this.confirmPassword,
          })
          
          console.log('2', response)
          // Verifica si el resultado indica éxito o error
          if (response.data?.passwordChange?.success) {
            Swal.fire({
              icon: "success",
              title: "Contraseña cambiada",
              text: "Tu contraseña ha sido cambiada exitosamente.",
            });
          }
        } catch (error) {
          // Captura y muestra el error
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Hubo un problema al cambiar la contraseña.",
          });
        }
      }
    },
    handleCancel() {
      this.$router.push("/"); // Redirige al dashboard
    },
  },
};
</script>
